const initialState = {
    response: ""
}

const participant = (state = initialState, action) => {
    switch (action.type) {
        case "DEMOGRAPHICS_DATA":
            return {
                ...state,
                demographicsData : action.result,
            }
        case "SET_PID":
            return {
                ...state,
                userPID : action.PID
            }    
        default:
            return state;
    }

};
export default participant;