import { startSpinner, endSpinner } from "../ui/elements/loader/Action";
import {createParticipant, saveUserAnswer, checkUserQuota, userFailedInScreening} from "../api/apiService";
import { setMessage } from "../ui/elements/alert/Action";
import Cookies from 'universal-cookie';



const cookies = new Cookies();

export const setDemographicsData = (result) => {
	return {
		type: "DEMOGRAPHICS_DATA",
		result
	}
}

export const setPIDofUser = (PID) => {
	return {
		type: "SET_PID",
		PID
	}
}

export const createParticipantAction = (allQueryParams, landingURL) => {
	return dispatch => {
		dispatch(startSpinner());
		createParticipant(allQueryParams, landingURL).then((result) => {
			
			dispatch(endSpinner());
			if (result.success) {
				// Set Cookie ID Here
				if(result.result.hasOwnProperty("cookieId") && result.result.cookieId > 0){
					cookies.set('userId', result.result.cookieId, { path: '/' });
				}
				if(result.result.responseType == "MESSAGE"){
					dispatch(setMessage( {success : false, message : result.result.message}));
				} else if(result.result.responseType == "REDIRECT"){
					setPIDofUser(result.result.PID);
					window.location.href = result.result.redirectURL;
				} else if(result.result.responseType == "DEMO_ALLOWED"){
					setPIDofUser(result.result.PID);
					//  Set Demographics Data Here
					dispatch(setDemographicsData(result.result));
				} else {
					dispatch(setMessage( {success : false, message : "Ooops ! There is some issue with link."}));
				}
			} else {
				dispatch(setMessage( {success : false, message : result.result.message}));
			}
		});
	}
}

export const saveUserAnswerAction = (body) => {
	return dispatch => {
		//dispatch(startSpinner());
		saveUserAnswer(body).then((result) => {
			
			//dispatch(endSpinner());
			
		});
	}
}

export const userFailedInScreeningAction = (body) => {
	return dispatch => {
		dispatch(startSpinner());
		userFailedInScreening(body).then((result) => {
			
			dispatch(endSpinner());
			if (result.success) {
				if(result.result.responseType == "MESSAGE"){
					dispatch(setMessage( {success : false, message : result.result.message}));
				} else if(result.result.responseType == "REDIRECT"){
					setPIDofUser(result.result.PID);
					window.location.href = result.result.redirectURL;
				} else {
					dispatch(setMessage( {success : false, message : "Ooops ! There is some issue with link."}));
				}
			} else {
				dispatch(setMessage( {success : false, message : result.message}));
			}
		});
	}
}

export const checkUserQuotaAction = (body) => {
	return dispatch => {
		dispatch(startSpinner());
		checkUserQuota(body).then((result) => {
			
			dispatch(endSpinner());
			if (result.success) {
				if(result.result.responseType == "MESSAGE"){
					dispatch(setMessage( {success : false, message : result.result.message}));
				} else if(result.result.responseType == "REDIRECT"){
					setPIDofUser(result.result.PID);
					window.location.href = result.result.redirectURL;
				} else {
					dispatch(setMessage( {success : false, message : "Ooops ! There is some issue with link."}));
				}
			} else {
				dispatch(setMessage( {success : false, message : result.message}));
			}
		});
	}
}