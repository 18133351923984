import React, { useEffect, useState } from 'react';
import {createParticipantAction, saveUserAnswerAction, checkUserQuotaAction, userFailedInScreeningAction} from "../../actions/participant";
import { connect } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Demographics = (props) => {
    // all Demos Here
    let [currentQuestion, setCurrentQuestion] = useState(0);
    let [userAnswer, setUserAnswer] = useState("");
    let [showDemoUID, setShowDemoUID] = useState(true);
    const [open, setOpen] = React.useState(false);


    const handleClick = () => {
        setOpen(true);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const onClickSave = ()=>{
        //
        console.log(userAnswer);

        if(userAnswer != undefined && userAnswer == ""){
            handleClick();
            return
        }

        let userGivenAnswer = userAnswer;
        let allDemoData = props.allDemos.allDemos;
        let currentQuestionData = allDemoData[currentQuestion];
        let isCorrect = false;
        let userAnserText = [];
        if((currentQuestionData.queryType == "dropdown") || currentQuestionData.queryType == "1"){
           isCorrect =  currentQuestionData.correctAnswerCode.includes(+userGivenAnswer);
           userAnserText.push(currentQuestionData.QuestionAnswerCodes.filter(mData=>mData.OId == userGivenAnswer)[0].optionText);
           userGivenAnswer = [userGivenAnswer];
        } else if((currentQuestionData.queryType == "radio") || (currentQuestionData.queryType == "2")){
            isCorrect =  currentQuestionData.correctAnswerCode.includes(+userGivenAnswer);
            userAnserText.push(currentQuestionData.QuestionAnswerCodes.filter(mData=>mData.OId == userGivenAnswer)[0].optionText);
            userGivenAnswer = [userGivenAnswer];
        } else if((currentQuestionData.queryType == "multiselect") || (currentQuestionData.queryType == "4")){

            // Need More Work Here
            userGivenAnswer = userGivenAnswer.split(",").map( Number );
            for(let key of userGivenAnswer){
                if(currentQuestionData.correctAnswerCode.includes(+key)){
                    isCorrect = true;
                } else {
                    isCorrect = false;
                    break;
                }
            }

            for(let key of userGivenAnswer){
                userAnserText.push(currentQuestionData.QuestionAnswerCodes.filter(mData=>mData.OId == key)[0].optionText)
            }

        } else if((currentQuestionData.queryType == "range") || (currentQuestionData.queryType == "5")){
            isCorrect =  currentQuestionData.correctAnswerCode.includes(+userGivenAnswer);
            userAnserText.push(userGivenAnswer);
            userGivenAnswer = [userGivenAnswer];
        } else if((currentQuestionData.queryType == "text") || (currentQuestionData.queryType == "3")){
            if (currentQuestionData.IsZipValidate === 1) {
                if (currentQuestionData.correctAnswerCode.length) {
                    isCorrect =   currentQuestionData.correctAnswerCode.includes(userGivenAnswer);
                } else {
                    isCorrect =  true;
                }
            } else {
                isCorrect = true;
            }
            userAnserText.push(userGivenAnswer);
            userGivenAnswer = [userGivenAnswer];
        } 

        if(isCorrect ){
            // Question is correct given by user
            // check is it a last question or not 
            if(currentQuestion == props.allDemos.allDemos.length - 1){
                // call check screener Quota API
                // Start Spinner

                let postData = {
                    PID : props.allDemos.PID,
                    userAnswer : userGivenAnswer, // it will be a array
                    QId : currentQuestionData.QId,
                    isCorrect : isCorrect,
                    userAnswerText : userAnserText // it will be a array
                }
                
                props.checkUserQuotaAction(postData);
                setShowDemoUID(false);
                // Hide Demo UI here

            } else {
                // save User Reply into table

                let postData = {
                    PID : props.allDemos.PID,
                    userAnswer : userGivenAnswer, // it will be a array
                    QId : currentQuestionData.QId,
                    isCorrect : isCorrect,
                    userAnswerText : userAnserText // it will be a array
                }
                
                props.saveUserAnswerAction(postData)
                setUserAnswer("");
                setCurrentQuestion(currentQuestion+1);
            }
        } else {
            // User failed in screening
            // Call User Failed in Screening API
            // Start Spinner

            
            let postData = {
                PID : props.allDemos.PID,
                userAnswer : userGivenAnswer, // it will be a array
                QId : currentQuestionData.QId,
                isCorrect : isCorrect,
                userAnswerText : userAnserText // it will be a array
            }
            
            props.userFailedInScreeningAction(postData)
             // Hide Demo UI here
            setShowDemoUID(false);
        }



        
        
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    let content = "";
    if(props.allDemos && props.allDemos.allDemos && showDemoUID){
       let allDemoData = props.allDemos.allDemos;

       let currentQuestionData = allDemoData[currentQuestion];
       let questionTextUI = <FormGroup style={{"marginTop" : "-20px"}}>
                                <p style={{"fontSize" : "25px", "width" : "100%", "textAlign" : "left"}}>{currentQuestionData.question} </p>
                            </FormGroup>
       
       
       let optionsUI = "";

       if((currentQuestionData.queryType == "dropdown") || (currentQuestionData.queryType == "1")){
        // Drop Down Feature
        optionsUI = getDropdownUI(currentQuestionData, userAnswer, setUserAnswer);
       } else if(currentQuestionData.queryType == "radio" || currentQuestionData.queryType == "2" ){
        optionsUI = getRadioOptions(currentQuestionData, userAnswer, setUserAnswer);
       } else if((currentQuestionData.queryType == "multiselect") || (currentQuestionData.queryType == "4")){
            optionsUI = getMultiSelect(currentQuestionData, userAnswer, setUserAnswer);
       } else if((currentQuestionData.queryType == "range") || currentQuestionData.queryType == "5"){
        optionsUI = getInputTextElement(currentQuestionData, userAnswer, setUserAnswer);
       } else if((currentQuestionData.queryType == "text") || (currentQuestionData.queryType == "3")){
        optionsUI = getInputTextElement(currentQuestionData, userAnswer, setUserAnswer);
       } 

       content = 
       <React.Fragment>
        <CssBaseline />
        <Container maxWidth="sm" >
        <Box sx={{height: '100vh' }} >
            <Card variant="outlined" style={{"margin" : "20px"}}>
                <CardContent>
                    {questionTextUI}
                    <Divider style={{"marginTop" : "-10px", "marginBottom" : "40px"}} />
                    {optionsUI}
                </CardContent>
                <CardActions style={{"float":"right" , "margin" : "10px"}}>
                    <Button  variant="contained" onClick={()=>onClickSave()} >Next</Button>
                </CardActions>
            </Card>

            <Snackbar open={open} autoHideDuration={2000}
             anchorOrigin={{ "vertical" : "top", "horizontal" : "center" }}
            onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                You didn't give any answer !
                </Alert>
            </Snackbar>
        </Box>
      </Container>
    </React.Fragment>
    } else {

    }



    return (
        <div>{content}</div>
    );

}

function getDropdownUI(currentQuestionData, userAnswer, setUserAnswer){
    let myDropdowns = currentQuestionData.QuestionAnswerCodes.map(function(item, index){
        return <MenuItem value= {item.OId}>{item.optionText}</MenuItem>
    });


    return <FormGroup >
            <Select
                value={userAnswer}
                onChange={e => setUserAnswer(e.target.value)}
                displayEmpty
                style={{"width" : "200px", "height" : "40px"}}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem value="">Please Select</MenuItem>
                {myDropdowns}
            </Select> 
            
             
    </FormGroup>
          
}

function getMultiSelect(currentQuestionData, userAnswer, setUserAnswer){ 
    let myDropdowns = currentQuestionData.QuestionAnswerCodes.map(function(item, index){
        let isCheck = false;
        if(userAnswer == ""){
            isCheck = false;
        } else {
            isCheck =  userAnswer.includes(new String(item.OId))
        }
        debugger
        return   <FormControlLabel control={<Checkbox checked = {isCheck} id={item.OId} name = "checkbox=[]" onClick = {e => {
                        
                            if(userAnswer == ""){
                                 userAnswer = new String(e.target.value)
                            } else {
                                if(userAnswer.includes(item.OId)){
                                    let allUserSelectdItems = userAnswer.split(",");
                                    let allRemainingItems = allUserSelectdItems.filter(mData=>mData != item.OId)
                                    userAnswer = allRemainingItems.join();
                                } else {
                                    userAnswer = userAnswer +","+e.target.value;
                                }
                            }                       
                            
                             setUserAnswer(userAnswer)
                    }} value={item.OId} />} label={item.optionText} />
    });

    return  <FormGroup>
                {myDropdowns}
            </FormGroup>
                
           
}

function getRadioOptions(currentQuestionData, userAnswer, setUserAnswer){
    let myDropdowns = currentQuestionData.QuestionAnswerCodes.map(function(item, index){
        return  <FormControlLabel value={item.OId} control={<Radio />} label={item.optionText} />
                
       
    });

    return  <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={userAnswer}
                onChange={e => setUserAnswer(e.target.value)}
            >
                <FormGroup>
                    {myDropdowns}
                </FormGroup>
            </RadioGroup>
}

function getInputTextElement(currentQuestionData, userAnswer, setUserAnswer){
    
    return  <FormGroup >
                <OutlinedInput  style={{"width" : "200px", "height" : "40px"}}  value={userAnswer}
                onChange = {e => setUserAnswer(e.target.value)} type="text"></OutlinedInput>   
            </FormGroup>
}

const mapStateToProps = (state) => {
    return {
       
    }
}

export default connect(mapStateToProps, {
     saveUserAnswerAction, userFailedInScreeningAction, checkUserQuotaAction
})(Demographics);