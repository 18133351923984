import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setMessage } from "../elements/alert/Action";
import {requestData} from "../helpers/requestData";
import {createParticipantAction, saveUserAnswerAction} from "../../actions/participant";
import Demographics from '../elements/demographics';
const Home = (props) => {

    let [createParticipantApiCalled, setCreateParticipantApiCalled] = useState();
    let [content, setContent] = useState();

    // Get Data Query from URL Here
    // Check the vid is Present
    // call the create Participant API

    let allRequestData = requestData(window);

    useEffect(()=>{
        if(props.alertMessage == "" && allRequestData.badUrlHitting){
            props.setMessage({ success: false, message: "You are hitting a bad url." })
        };

       
    }, [props.alertMessage]);

    // Call the Create Participant API
    
    useEffect(()=>{
        if(!createParticipantApiCalled && !allRequestData.badUrlHitting){
            // call action of API
            let allQueryParams = allRequestData.urlQueryString;
            let landingURL = allRequestData.landingURL;
            setCreateParticipantApiCalled(true);
            props.createParticipantAction(allQueryParams, landingURL);
            
        }
    })

    useEffect(()=>{

        let demos = "";
        if(props.demographicsData){
            demos =   <Demographics allDemos = {props.demographicsData}/>
            setContent(demos)
        }

    }, [props.demographicsData])


    return (
        <div>
            {content}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        alertMessage: state.alert.alertMessage,
        demographicsData : state.participant.demographicsData
    }
}
export default connect(mapStateToProps, {
    setMessage, createParticipantAction
})(Home);